<!-- 页面程序 -->
<template>  
  <div class="content">
    <img class="topImage" :src="activityTopImg" />
    <div class="component">
        <div class="component_title">新人福利下单即可抵扣</div>
        <div class="component_content" >
            <div class="component_content_item" v-for="(item, index) in giftList" :key="index">
                <img src="http://resources.sxdjcq.com/mini/wash/icon_newPeople_gift_bg.png" class="component_content_img" />
                <div class="component_content_price">
                    <section class="component_content_price_couponFee" v-if="item.couponType != 3">
                        <span class="font11">￥</span>
                        <span class="font22">{{item.couponFee / 100}}</span>
                    </section>
                    <section class="component_content_price_couponFee" v-else>
                        <span class="font22">{{ item.rate * 10 }}</span>
                        <span class="font11">折</span>
                    </section>
                    <div class="component_content_price_name">{{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="component_btn" @click="getGifyData"> 立即领取 </div>
        <div class="component_subTitle"> 领取后可在舒昕逸家我的-个人中心-优惠券查看</div>
    </div>
    <div class="project_title_view">
        <div class="project_title_left_dot"></div>
        <div class="project_title_text">新人专享体验</div>
        <div class="project_title_right_dot"></div>
    </div>
    <div>
        <div class="project_info" v-for="(item, index) in activityList" :key="index" @click="getServiceData(item)">
            <img :src="item.uri" class="project_info_img" />
            <div class="project_info_title">{{item.projectName}}</div>
            <div class="project_info_subtitle">{{item.description}}</div>
            <div class="project_info_bottom">
                <img src="http://resources.sxdjcq.com/mini/wash/icon_people_project.png" style="height:37px;" />
                <aside class="project_info_price_subtitle" >
                    <span style="font-size: 9px;">新人价:￥</span>
                    <span class="font16" style="font-weight: 800; color: #EE4908;">{{1 > item.oltCount ? (item.minimumNewcomerDiscount/100).toFixed(0) : (item.lowestDiscountPrice/100).toFixed(0)}}</span>
                    <span style="font-size: 9px;">/小时</span>
                </aside>
                <aside class="project_info_price_title">
                    ￥{{(item.theLowestPrice/100).toFixed(0)}}/小时
                </aside>
            </div>
        </div>
    </div>
    <div class="porject_recommend">
        <section style="display: flex;justify-content: center;align-items: center; height: 40px; color: #999;">
            <span style="width: 50px; height: 1px; background: #999;" ></span>
            <span style="margin-left: 20px;margin-right: 20px;">推荐服务</span>
            <span style="width: 50px; height: 1px; background: #999;"></span>
        </section>
        <section class="porject_recommend_bottom">
            <div class="porject_recommend_scroll_item" v-for="(item, index) in recommendList" :key="index" @click="getServiceData(item)">
                <section style="display: flex;flex-direction: column;">
                    <img class="spikeByTime_img" style="border-radius:10px; height: 120px;" :src="item.photoPath" />
                    <span class="spikeByTime_name">{{item.projectName}}</span>
                    <span class="spikeByTime_desc">{{item.description}}</span>
                    <div class="spikeByTime_price">
                        <span class="font16" style="width: 50%;font-weight: 800; color: #EE4908;">￥{{1 > item.oltCount ? (item.minimumNewcomerDiscount/100).toFixed(2) : (item.lowestDiscountPrice/100).toFixed(2)}}</span>
                        <span style="color: #666666;text-decoration: line-through;font-size: 12px;">￥{{(item.theLowestPrice/100).toFixed(2)}}</span>
                    </div>
                </section>
            </div>
        </section>
    </div>
    <div class="bottom_img">
        <img :src="activityBottomImg" style="width: 100% ; height: 100%; "/>
    </div>
  </div>
</template>


<!-- JS交互 -->
<script>
export default {
  components: {},
  data() {
    return {
        activityId: this.$route.query.activityId,
        memberId: this.$route.query.memberId,
        activityTopImg:"",
        activityBottomImg:"",
        activityList:[],
        giftList: [],
        recommendList: [],
    };
  },
  methods: {
    getBaseInfo() {
      let params = {
        activityId: this.activityId,
        memberId: this.memberId,
      };
      this.$axios({
        url: this.$requests.member.getNewActiveDataList,
        method: "post",
        data: params,
      }).then((res) => {
        let wrapper = res.data || {};
        let list = wrapper.data || [];
        let top_data = list.shift() 
        let array = list.reverse()
        let bottom_data = array.shift() 
        let activityList = list.reverse()

        this.activityTopImg = top_data.aslrList[0].uri,
        this.activityBottomImg =  bottom_data.aslrList[0].uri,
        this.activityList = activityList[0].aslrList
      }).catch((e) => {});
    },
    getReferralServiceList() {
        let params = {
            placeAnOrder: 0,
            memberId: this.memberId,
        };
        this.$axios({
            url: this.$requests.member.getNewActiveSalesList,
            method: "post",
            data: params,
        }).then((res) => {
            let wrapper = res.data || {};
            this.recommendList = wrapper.data || [];
        }).catch((e) => {});
    },
    getNewPeopleGiftList(){
        this.$axios({
            url: this.$requests.member.getWeddingGiftPackage,
            method: "post",
            data: {},
        }).then((res) => {
            let wrapper = res.data || {};
            this.giftList = wrapper.data || [];
        }).catch((e) => {});
    },
    getGifyData(){
        window.location.href = "next://getGifyData";
    },
    getServiceData(e){
        let href =  "next://getServiceData" + ',' + e.projectId
        window.location.href = href;
    },
  },
  mounted() {
    // 获取活动详情信息
    this.getBaseInfo();
    // 获取推荐数据
    this.getReferralServiceList();
    // 获取新人大礼包优惠券列表
    this.getNewPeopleGiftList();
  },
};
</script>

<!-- class 展示属性 -->
<style lang="less" scoped>
.content {
    background: #FED0B5;
}
.topImage{
    width: 100%;
}

.component{
    margin-left: 2%;
    margin-top: 2%;
    width: 96%;
    height: 340px;
    background: #F4E8D8;
    border: 1px solid #A67D1A;
    box-shadow: 0px 1px 11px 2px rgba(139,80,18,0.1500);
    border-radius: 11px;
} 
.component_title {
    width: 100%;
    height: 20%;
    font-size: 25px;
    font-weight: 500;
    color: #C18B1B;
    display: flex;
    align-items: center;
    justify-content: center;
}   
.component_content{
    width: 100%;
    height: 55%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.component_content_item {
    width: 40%;
    height: 90%;
    border-radius: 20rpx;
    position: relative;
}
.component_btn {
    margin-left: 10%;
    width: 80%;
    height: 15%;
    background: #EE4908;
    border: 1px solid #A67D1A;
    box-shadow: 0px 0px 16px 0px rgba(158,45,0,0.4900);
    border-radius: 34px;
    font-size: 36rpx;
    font-weight: 500;
    color: #FFFFFE;
    display: flex;
    align-items: center;
    justify-content: center;
}
.component_subTitle {
    height: 10%;
    font-size: 20rpx;
    font-weight: 400;
    color: #EE4908;
    display: flex;
    align-items: center;
    justify-content: center;
}
.component_content_img {
    width: 100%;
    height: 100%;
}

.project_title_view {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.project_title_left_dot {
    width: 9px;
    height: 9px;
    background: #C18B1B;
    box-shadow: 0px 1px 0px 0px rgba(136,100,13,0.8800);
    border-radius: 50%;
    margin-right: 20px;
}
.project_title_text{
  font-size: 20px;
  font-weight: 500;
  color: #C18B1B;
  text-shadow: 0px 1px 0px rgba(80,58,5,0.8800);
  display: flex;
  align-items: center;
  justify-content: center;
}
.project_title_right_dot{
  width: 9px;
  height: 9px;
  background: #C18B1B;
  box-shadow: 0px 1px 0px 0px rgba(136,100,13,0.8800);
  border-radius: 50%;
  margin-left:20px;
}
.project_info {
    margin-left: 2%;
    margin-top: 2%;
    width: 96%;
    height: 260px;
    background: #FFF9F1;
    border: 1px solid #A67D1A;
    box-shadow: 0px 1px 11px 2px rgba(139,80,18,0.1500);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}
.project_info_img {
    margin-top: 3%;
    margin-left: 3%;
    width: 94%;
    height: 50%;
    border-radius: 5px;
}
.project_info_title {
    margin-top: 3%;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    text-align: center;
}
.project_info_subtitle {
    margin-top: 6px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.project_info_bottom {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.project_info_price_subtitle {
    position: absolute;
    left: calc(50vw - 150px);
    color: #EE4908;
    height: 19px;
}

.project_info_price_title {
    position: absolute;
    left: calc(50vw - 40px);
    color: #666666;
    text-decoration: line-through;
    font-size: 12px;
}
.porject_recommend {
    width: 100%;
    height: 230px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.bottom_img {
    width: 100%;
    margin-top: 20px;
}

.porject_recommend_bottom {
    height: 205px;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    overflow-y: hidden;
}
.porject_recommend_scroll_item {
    margin-top: 5px;
    margin-right: 5px;
    height: 180px;
    width: 35vw;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 9px 1px rgba(179,110,69,0.4600);
    border-radius: 5px;
    overflow: hidden;
    display: inline-table;
}
.component_content_price{
  width: 100%;
  height: 100%;
  position:absolute;
  left: 0;
  top: 0;
}
.component_content_price_couponFee{
  width: 100%;
  height: 60%;
  font-weight: 800;
  color: #EE4908;
  display: flex;
  justify-content: center;
  align-items: center;
}
.component_content_price_name{
  width: 100%;
  height: 40% ;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spikeByTime_img {
    width: 100%;
    height: 55%;
}
.spikeByTime_name{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 2px;
    margin-left: 1vw;
    font-size: 14px;
    color: #333333;
    font-weight: 500;
}
.spikeByTime_desc{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 33vw;
    margin-top: 5px;
    margin-left: 1vw;
    font-size: 11px;
    color: #666666;
    font-weight: 500;
}
.spikeByTime_price{
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 20px;
    align-items: baseline;
}
</style>